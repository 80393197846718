import dayjs from "dayjs";

//*******************************//
//******* SLOT UTILITIES ********//
//*******************************//

// DISPLAY THE SLOT NUMBER
export const slotNumberString = (slot) => {
  const numberOfSlotsInSession = slot.session.slots.length;
  return numberOfSlotsInSession > 1
    ? "(" +
        (slot.session.slots.findIndex((s) => s._id === slot._id) + 1) +
        "/" +
        numberOfSlotsInSession +
        ") "
    : "";
};

export const getSessionElementsFromListInSlot = (elementName, slot, getKey: (s: any) => string) =>
  slot[elementName]?.length > 0 && slot[`${elementName}SessionSynchro`] === false
    ? slot[elementName].map(getKey)
    : slot.session[elementName].map(getKey);

//*****************************//
//******* AGENDA PARAMS *******//
//*****************************//

// Cell sizes and duration
export const CELL_DURATION_MINUTES = 30; // The smallest unit of time for drag and drop
export const CELLS_DISPLAY_HEIGHTS = [25, 35, 45];

// Max recommended number of simultaneous columns
export const RECOMMENDED_LIMIT_OF_RESOURCE_COLUMNS = 15;

// DEFAULT AGENDA PARAMS
export const DEFAULT_AGENDA_PARAMS = {
  slotsOnEachOther: false,
  cellDisplayHeight: CELLS_DISPLAY_HEIGHTS[1],
  groupByDaysFirst: true,
  defaultNewSlotDuration: 90,
  displayAvailabilities: true,
  selectedResources: [],
  showResourcesListingOnAgendaCards: [
    "places",
    "stewards",
    "registrations",
    "maxNumberOfParticipants",
    "activityTags",
    "sessionTags",
  ],
  resourcesFilterSelections: {},
  showResourcesAvailabilities: {},
  categoriesFilter: [],
};

// Number of days to display by default
const MAX_DAYS_TO_DISPLAY_BY_DEFAULT = 4;
export const getNumberOfDaysToDisplay = (windowWidth, project) =>
  Math.min(
    Math.max(1, Math.ceil((windowWidth - 280) / 450)), // Screen adaption
    dayjs(project.end).diff(project.start, "day") + 1, // Not more than the project opening dates
    MAX_DAYS_TO_DISPLAY_BY_DEFAULT // Default limit
  );

export const getAgendaDisplayStartDate = (project) => {
  const now = dayjs();
  const start = dayjs(project.start);
  const end = dayjs(project.end);

  // If we are during the event, start at the current date. Else, start at project start date
  return (now.isBefore(start) || now.isAfter(end) ? start : now).unix() * 1000;
};
