import {useAgendaParams} from "./useAgendaParams";
import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {currentProjectSelectors} from "../../../features/currentProject";
import {useDebounce} from "../../../common/hooks/useDebounce";
import {Button, Input, Popover, Space} from "antd";
import {t} from "i18next";
import {CheckboxGroupComponent} from "../../../common/inputs/CheckboxGroupInput";
import {SettingOutlined} from "@ant-design/icons";

export const getAllSearchScopes = (currentProject) =>
  [
    {label: t("activities:schema.name.label"), value: "activity"},
    {label: t("sessions:schema.name.label"), value: "session"},
    {label: t("categories:label"), value: "category"},
    {label: t("activities:schema.secondaryCategories.label"), value: "secondaryCategories"},
    {label: "Tags de l'activité", value: "activityTags"},
    {label: "Tags de la session", value: "sessionTags"},
    {label: t("stewards:label_other"), value: "stewards"},
    currentProject.usePlaces && {label: t("places:label"), value: "places"},
    currentProject.useTeams && {label: t("teams:label"), value: "team"},
    {label: t("registrations:label_other"), value: "registrations"},
  ].filter((el) => el);

export const SearchBox = () => {
  const [{searchBarValue}, setAgendaParams] = useAgendaParams();
  const searchInput = useRef();
  const currentProject = useSelector(currentProjectSelectors.selectProject);

  const debouncedSetSearchBarValue = useDebounce((value) =>
    setAgendaParams({searchBarValue: value})
  );

  const defaultScopes = searchBarValue.scopes;
  const [scopes, setScopes] = useState(defaultScopes);

  const launchSearch = (values?: Array<string>) => {
    if (values) setScopes(values);
    debouncedSetSearchBarValue({text: searchInput.current.input.value, scopes: values || scopes});
  };

  return (
    <Space.Compact style={{minWidth: 150, flexBasis: "50%"}}>
      <Input.Search
        placeholder="Rechercher..."
        onChange={() => launchSearch()}
        ref={searchInput}
        defaultValue={searchBarValue.text}
      />

      <Popover
        trigger={"click"}
        content={
          <>
            <p style={{color: "gray"}}>Rechercher dans les champs suivants :</p>
            <CheckboxGroupComponent
              options={getAllSearchScopes(currentProject)}
              defaultValue={scopes}
              onChange={launchSearch}
            />
          </>
        }>
        <Button icon={<SettingOutlined />} />
      </Popover>
    </Space.Compact>
  );
};
