import dayjs from "dayjs";
import {filterSessionsByCategories} from "./filterSessionsByCategories";

/**
 * Filter by period functions. Each one allow to filter a part of the sessions list depending on the period
 */
const periodFilterFunctions = {
  hidePastSessions: (session) => dayjs().isBefore(session.end),
  showNextSessions: (session) => dayjs().isBefore(session.start),
  showOngoingSessions: (session) => dayjs().isBefore(session.end) && dayjs().isAfter(session.start),
  all: () => true,
};

/**
 * Filters sessions based on search bar value, categories filter, and filter period.
 * @param {Array} sessions - The array of sessions to filter.
 * @param {Array} categoriesFilter - The array of categories to filter sessions.
 * @param {string} filterPeriod - The filter period ("hidePastSessions", "showNextSessions", "showOngoingSessions", or "all").
 * @returns {Array} - The filtered array of sessions.
 */
export const filterSessions = (sessions, categoriesFilter, filterPeriod) => {
  const filterByPeriod = periodFilterFunctions[filterPeriod] || periodFilterFunctions.all;

  // Filter sessions based on filter period
  const filteredSessions = sessions.filter(filterByPeriod);

  // Return sessions filtered by categories
  return filterSessionsByCategories(filteredSessions, categoriesFilter);
};
