import {slotEndIsBeforeBeginning} from "./slotsUtilities";
import {t} from "i18next";
import {shouldShowField} from "../common/components/FormRenderer/shouldShowField";
import {truncate} from "./stringUtilities";
import dayjs from "dayjs";
import {getSessionsPlanningForRegistration} from "../routes/participants/atoms/getSessionsPlanningForRegistration";

export const flattenFormComponents = (components, keepOnlyInputs = true) => {
  if (!components) return [];

  const tree = components.map((comp) => {
    if (comp.input) {
      // If the component is a data input one, return it
      return comp;
    } else {
      // If the component is not an input, check if it has child components and return them.
      let branchs = [];
      // Put together all child components present in the "components" and "columns" arguments, and then apply recursively the flattenFormComponents function on it
      if (comp.components) branchs = branchs.concat(comp.components);
      if (comp.columns) branchs = branchs.concat(comp.columns);
      return flattenFormComponents(branchs, keepOnlyInputs);
    }
  });

  // Flatten the return value
  const flattenedComponents = tree.flat();
  return keepOnlyInputs ? flattenedComponents.filter((comp) => comp.input) : flattenedComponents;
};

export const getRegistrationMetadata = (registration, project) => {
  const data = {};
  if (registration) {
    // Availabilities are clean
    const firstSlot = registration.availabilitySlots?.[0];
    const firstSlotIsDefined = !!(firstSlot?.start && firstSlot?.end);
    data.firstSlotEndIsBeforeBeginning = firstSlotIsDefined && slotEndIsBeforeBeginning(firstSlot);
    data.firstSlotIsOk = firstSlotIsDefined && !data.firstSlotEndIsBeforeBeginning;

    data.datesAlert =
      registration.booked && !firstSlotIsDefined
        ? t("registrations:registrationValidation.selectDatesToRegister")
        : data.firstSlotEndIsBeforeBeginning
        ? t("registrations:registrationValidation.endCannotBeBeforeStart")
        : undefined;

    // All mandatory form fields are filled
    const unfulfilledMandatoryFormFields = [];
    const flatFormComponents = flattenFormComponents(project.formComponents);
    for (let formComp of flatFormComponents) {
      if (formComp.required) {
        let invalid = false;
        if (registration.formAnswers) {
          const value = registration.formAnswers[formComp.key];

          if (shouldShowField(formComp.conditional, flatFormComponents, value)) {
            if (
              // No value at all
              value === undefined ||
              value === null ||
              // Checkbox not checked
              value === false ||
              // Empty text
              value === "" ||
              // Empty array
              (Array.isArray(value) && value?.length === 0)
            ) {
              invalid = true;
            } else if (Array.isArray(value)) {
              // Checkbox group / Multiselect number of selected elements
              const {min, max} = formComp.selectedCount || {};
              if ((min && value.length < min) || (max && value.length > max)) {
                invalid = true;
              }
            } else if (typeof value === "number") {
              const {min, max} = formComp.minMaxNumber || {};
              if ((min && value < min) || (max && value > max)) {
                invalid = true;
              }
            }
          }
        } else {
          invalid = true;
        }

        if (invalid) unfulfilledMandatoryFormFields.push(formComp.label);
      }
    }
    data.formIsOk = unfulfilledMandatoryFormFields.length === 0;
    data.formAlert =
      registration.booked && !data.formIsOk
        ? t("registrations:registrationValidation.someFormFieldsAreMandatory", {
            list: unfulfilledMandatoryFormFields.map((field) => truncate(field, 30)).join('", "'),
          })
        : undefined;

    // Mandatory Ticketing
    data.ticketingIsOk =
      !project.ticketingMode || registration?.[`${project.ticketingMode}Tickets`]?.length > 0;
    data.ticketingAlert =
      registration.booked && !data.ticketingIsOk
        ? t("registrations:registrationValidation.ticketingIsMandatory")
        : undefined;

    data.everythingIsOk = data.firstSlotIsOk && data.formIsOk && data.ticketingIsOk;

    return data;
  }
};

export const getInitializedRegistration = (registration, project, initializeDatabaseMetadata) => {
  const validationFields = getRegistrationMetadata(registration, project);
  const newRegistration = {
    ...registration,
    ...validationFields, // instant validation fields, always up to date
    touched: !initializeDatabaseMetadata, // To tell if the registration has been touched and is not in sync anymore with database
  };
  if (initializeDatabaseMetadata) {
    newRegistration.inDatabase = validationFields; // To keep a copy of the saved state
  }
  return newRegistration;
};

export const getSessionSubscription = (registration, session) =>
  registration?.sessionsSubscriptions?.find((ss) => ss.session === session?._id);

export const getNextOrCurrentSession = (registration, allSlots, teams, project) => {
  const now = dayjs();
  return (
    allSlots &&
    teams &&
    getSessionsPlanningForRegistration(
      registration,
      allSlots,
      teams,
      project.sessionNameTemplate
    ).find(
      (sessionPlanned) =>
        now.isBefore(sessionPlanned.startDate) ||
        (now.isAfter(sessionPlanned.startDate) && now.isBefore(sessionPlanned.endDate))
    )
  );
};
